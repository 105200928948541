'use client'

import { Inter } from 'next/font/google'
import './globals.css'
import { Providers } from './SessionProvider'
import { ErrorBoundary } from 'react-error-boundary'
import { CartProvider } from './CartContext'
import { ChakraProvider } from "@chakra-ui/react";

const inter = Inter({ subsets: ['latin'] })

function ErrorFallback({error}) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
    </div>
  )
}

export default function RootLayout({ children }) {
  return (
    <html lang="en">
      <body>
        <ChakraProvider>
          <Providers>
            {children}
          </Providers>
        </ChakraProvider>
      </body>
    </html>
  )
}